import { AuthPageTitles, RundooHead } from "components/Head"

import { NextPage } from "next"
import { SignIn } from "features/auth/SignIn"
import { SupportChatButton } from "components/SupportChatButton"
import { Toaster } from "react-hot-toast"
const AuthSignInPage: NextPage = () => {
  return (
    <>
      <RundooHead headTitle={AuthPageTitles.SIGN_IN} />
      <SignIn />
      <Toaster position="bottom-center" />
      <SupportChatButton className="fixed right-6 top-6 z-20" />
    </>
  )
}

export default AuthSignInPage
